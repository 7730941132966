import { db } from '../../firebase/index'
import { collection,getDocs,getDoc,doc,query, orderBy  } from 'firebase/firestore';
export default {
  namespaced: true,
  state: {
    nftData:null,
    blank_nftData : null,
    nftDetaildata : null,
  },
  getters: {},
  mutations: {
    SET_NFT_DATA(state,data){
      state.nftData = data
    },
    SET_BLANK_NFT_DATA(state,data){
      state.blank_nftData = data
    },
    SET_NFT_DETAIL_DATA(state,data){
        state.nftDetaildata = data
    },
  },
  actions: {
    async getNftData({commit}){
      let nftsRef = collection(db,'nfts')
      let snapshotNfts = await getDocs(nftsRef);
      let nfts = [];
      snapshotNfts.forEach(nft=>{
        let nftid = nft.id
        let nftdata = nft.data()
        nftdata.id = nftid
        nfts.push(nftdata)
      })
      commit('SET_NFT_DATA',nfts)
      commit('SET_BLANK_NFT_DATA',nfts)

    },
    async getNftDetailData({commit},id){
        //메인디테일 받아오는 부분
        let detailRef = doc(db, "nfts", id);
        let detailNft = await (await getDoc(detailRef)).data();
        detailNft.Id = id

        //detail - info 정보 받아오는부분
        let infos = []
        let infoRef = collection(db,'nfts',id,'info')
        let snapshotInfoNfts = await getDocs(infoRef)
        snapshotInfoNfts.forEach(info=>{
          let infoData = info.data()
          infoData.id = info.id
          infos.push(infoData)
        })
        detailNft.info = infos
        
        //민팅정보 받아오는부분
        let mintings = []
        let mintingRef = collection(db,'nfts',id,'minting')
        let queryMinting = query(mintingRef,orderBy('date','asc'))
        let snapshotMintingNfts = await getDocs(queryMinting)
        snapshotMintingNfts.forEach(minting=>{
          let mintingData = minting.data()
          mintingData.id = minting.id
          mintings.push(mintingData)
        })
        detailNft.mintings=mintings
        commit('SET_NFT_DETAIL_DATA',detailNft)
    },  
    


   
    
  },
}
